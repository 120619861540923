import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import loopStyles from 'common/styles';
import styled, { keyframes } from 'styled-components';
import { Feature } from 'common/feature';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import YouTubeVideo from 'components/common/YouTubeVideo';
import LinkIcon from '@mui/icons-material/Link';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import CircularProgress from '@mui/material/CircularProgress';
import TrainIcon from '@mui/icons-material/Train';

// import { ZoomInIcon } from '@material-ui/icons/ZoomIn';
import { ZoomIn as ZoomInIcon, CenterFocusWeak as CenterFocusWeakIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRoutePath, updatePath, updateSegmentRouteState, updateSelectedSectionIndex, zoomToSelection, updateHighlightFeatureIDs } from 'store/reducers/map';
import { AnimatePresence, motion } from 'framer-motion';

enum TabKey {
    Info,
    Options,
    Paths,
    Study,
}

interface RouteLayoutProps {
    feature: Feature,
    toggleExpanded: () => void,
    classes: any,
}

const RouteLayout = (props: RouteLayoutProps) => {
    // Redux state
    const dispatch = useDispatch();

    const isMobile = useSelector((state: any) => state.global.isMobile)
    const segmentRouteState = useSelector((state: any) => state.map.segmentRouteState)
    const pathData = useSelector((state: any) => state.map.pathData)
    const menuData = useSelector((state: any) => state.menu.data)
    const isLoadingPath = useSelector((state: any) => state.map.isLoadingPath)
    const useImperialUnits = useSelector((state: any) => state.user.useImperialUnits)

    // Local State
    // const [tabIndex, setTabIndex] = useState(0)
    const [tabIndex, setTabIndex] = useState(TabKey.Study)
    const [tab, setTab] = useState<TabKey | undefined>(undefined)
    const [departureStationID, setDepartureStationID] = useState<any>(undefined)
    const [arrivalStationID, setArrivalStationID] = useState<any>(undefined)

    const { feature, classes } = props;

    const distanceKey = useImperialUnits ? 'distanceMI' : 'distanceKM'
    const avgSpeedKey = useImperialUnits ? 'avgSpeedMPH' : 'avgSpeedKMH'
    const topSpeedKey = useImperialUnits ? 'topSpeedMPH' : 'topSpeedKMH'

    const distance = feature && feature[distanceKey] !== "" ? `${feature[distanceKey]} ${useImperialUnits ? 'mi' : 'km'}` : '—'
    const avgSpeed = feature && feature[avgSpeedKey] !== "" ? `${feature[avgSpeedKey]} ${useImperialUnits ? 'mph' : 'kmh'}` : '—'
    const travelTime = feature && feature.travelTime !== "" ? `${feature.travelTime} ` : '—'
    const topSpeed = feature && feature[topSpeedKey] !== "" ? `${feature[topSpeedKey]} ${useImperialUnits ? 'mph' : 'kmh'}` : '—'

    const selectedChildFeatureIDs = segmentRouteState
        ?.map((optionValue: number, sectionIndex: number) => Array.from(new Set(feature.segmentData[sectionIndex][optionValue]?.childFeatures)))
        ?.flat()
        ?.filter((featureID: string) => !featureID.includes('_L'))

    const isStatsEmpty = [distance, avgSpeed, travelTime, topSpeed].every(detail => detail === '—')
    const isDetailsEmpty = isStatsEmpty && (feature?.description === '' || feature?.description === ' ') && !feature.sourceUrl
    const isOptionsEmpty = !(!!feature?.segmentStructure && !(feature?.segmentStructure.length === 1 && [0,1].includes(feature?.segmentStructure[0])))
    const isPathsEmpty = selectedChildFeatureIDs?.length <= 1
    const isGreatLakes = feature?.featureID === 'HTT_101'

    const kmToMi = (kilometers: number) => {
        return kilometers * 0.621371
    }

    const stringifyHours = (totalHours: number) => {
        const hours = Math.floor(totalHours)
        const minutes = Math.floor((totalHours % 1) * 60)

        const hoursString = hours ? `${hours} hr${hours > 1 ? 's' : ''}` : ''
        const minString = minutes ? `${minutes} min` : ''

        return !hours && !minutes ? '-' : `${hoursString} ${minString}`
    }

    const getFirstTab = () => {
        if (!isPathsEmpty) {
            return TabKey.Paths
        }
        if (!isDetailsEmpty) {
            return TabKey.Info
        }
        if (!isOptionsEmpty) {
            return TabKey.Options
        }
        return undefined
    }

    useEffect(() => {
        setTabIndex(0)
        setTab(getFirstTab())
        dispatch(updatePath(undefined))
        if (selectedChildFeatureIDs?.length === 2) {
            setDepartureStationID(selectedChildFeatureIDs[0])
            setArrivalStationID(selectedChildFeatureIDs[1])
        } else {
            setDepartureStationID(undefined)
            setArrivalStationID(undefined)
        }
    }, [props.feature]) // eslint-disable-line

    useEffect(() => {
        if (arrivalStationID && departureStationID) {
            const key = [departureStationID, arrivalStationID].sort((aid, bid) => Number(aid.split('_')[2]) - Number(bid.split('_')[2])).join('+')
            const stateKey = segmentRouteState.map((val: number) => val + 1).join('')
            dispatch(fetchRoutePath({
                routeID: props.feature.featureID,
                pathKey: `${stateKey}_${key}`
            }))
        }
    }, [arrivalStationID, departureStationID]) // eslint-disable-line

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue)
    }

    useEffect(() => {
        if (tab !== TabKey.Paths) {
            dispatch(updatePath(undefined))
            setDepartureStationID(undefined)
            setArrivalStationID(undefined)
        }
    }, [tab, dispatch])

    const getTabContent = (tabIndex: number) => {
        const { feature, toggleExpanded } = props;

        if (feature === undefined) { return }

        let content = undefined

        switch (tab) {
            case TabKey.Info:
                content = <DetailsContainer>
                    {feature?.youtube && <div key={feature?.featureID} style={{ height: '225px' }}>
                        <YouTubeVideo link={feature?.youtube} />
                    </div>}
                    {!isDetailsEmpty ? <>
                        {!isStatsEmpty && <div style={{display: 'flex', width: '100%', textAlign: 'center', color: '#FFFFFF', margin: '20px 0'}}>
                            <div style={{width: '25%', borderRight: '1px solid #B7B7B7'}}>
                                <div>Distance</div>
                                <div style={{fontSize: '14px', color: '#aaa'}}>{distance}</div>
                            </div>
                            <div style={{width: '25%', borderRight: '1px solid #B7B7B7'}}>
                                <div>Top Speed</div>
                                <div style={{fontSize: '14px', color: '#aaa'}}>{topSpeed}</div>
                            </div>
                            <div style={{width: '25%', borderRight: '1px solid #B7B7B7'}}>
                                <div>Avg Speed</div>
                                <div style={{fontSize: '14px', color: '#aaa'}}>{avgSpeed}</div>
                            </div>
                            <div style={{width: '25%'}}>
                                <div>Time</div>
                                <div style={{fontSize: '14px', color: '#aaa'}}>{travelTime}</div>
                            </div>
                        </div>}
                        {feature.sourceUrl && <div style={{ display: 'flex', alignItems: 'center', margin: '10px 20px', overflow: 'hidden'}}>
                            <LinkIcon style={{ color: loopStyles.colors.secondary, marginRight: '10px' }}/>
                            <a href={feature.sourceUrl} rel='noreferrer' target='_blank' style={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '14px',
                                lineHeight: '23px',
                                letterSpacing: '0.1px',
                                color: loopStyles.colors.tertiary,
                                // textWrap: 'wrap',
                                // wordBreak: 'break-all',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}>{feature.sourceUrl}</a>
                        </div>}
                        {feature.description && <div style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '16px',
                            lineHeight: '23px',
                            letterSpacing: '0.1px',
                            color: '#B7B7B7',
                            margin: '10px 20px',
                            overflow: 'hidden',
                        }}>
                            {feature.description}
                        </div>}
                    </> : <EmptyDetailsLabel>
                        Details for this route aren't currently available.
                    </EmptyDetailsLabel>}
                </DetailsContainer>
                break;
            case TabKey.Options:
                content = feature?.segmentStructure && segmentRouteState && <OptionsContainer>
                    {feature.segmentStructure.map((sectionLength: any, sectionIndex: number) => {
                        const segmentFeature = feature.segmentData[sectionIndex][segmentRouteState[sectionIndex]]

                        const distance = useImperialUnits ? `${segmentFeature?.distanceMI === '' ? '—' : segmentFeature?.distanceMI} mi` : `${segmentFeature.distanceKM === '' ? '—' : segmentFeature.distanceKM} km`
                        const topSpeed = useImperialUnits ? `${segmentFeature?.topSpeedMPH === '' ? '—' : segmentFeature?.topSpeedMPH} mph` : `${segmentFeature.topSpeedKMH === '' ? '—' : segmentFeature.topSpeedKMH} kmh`
                        const avgSpeed = useImperialUnits ? `${segmentFeature?.avgSpeedMPH === '' ? '—' : segmentFeature?.avgSpeedMPH} mph` : `${segmentFeature.avgSpeedKMH === '' ? '—' : segmentFeature.avgSpeedKMH} kmh`
                        const travelTime = segmentFeature?.travelTime === '' ? '— min' : segmentFeature?.travelTime
                        const isOptionDataEmpty = [distance, topSpeed, avgSpeed, travelTime].every(unit => unit.includes('—'))

                        return <div key={`segment:${sectionIndex}`} style={{ width: 'calc(100% - 40px)', padding: '10px 20px', borderTop: sectionIndex > 0 ? `1px solid #555` : '' }}>
                            <div style={{
                                fontFamily: 'Poppins',
                                marginBottom: '8px',
                                fontStyle: 'normal',
                                fontWeight: 700,
                                fontSize: '18px',
                                lineHeight: '18px',
                                letterSpacing: '0.1px',
                                color: '#FFFFFF',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                textOverflow: 'ellipsis'
                            }}>
                                {feature.segmentData[sectionIndex][segmentRouteState[sectionIndex]]?.name}
                                <div style={{ display: 'flex' }}>
                                    <ZoomButton onClick={() => { 
                                        // dispatch(updateSelectedSectionIndex(sectionIndex))
                                        dispatch(updateHighlightFeatureIDs({
                                            highlightFeatureIDs: feature.segmentData[sectionIndex][segmentRouteState[sectionIndex]]?.childFeatures,
                                        }))
                                        isMobile && toggleExpanded()
                                    }}>
                                        <CenterFocusWeakIcon />
                                    </ZoomButton>
                                    <ZoomButton onClick={() => {
                                        dispatch(updateSelectedSectionIndex(sectionIndex));
                                        // dispatch(updateHighlightFeatureIDs({
                                        //     highlightFeatureIDs: feature.segmentData[sectionIndex][segmentRouteState[sectionIndex]]?.childFeatures,
                                        // }))
                                        dispatch(zoomToSelection())
                                        isMobile && toggleExpanded()
                                    }}>
                                        <ZoomInIcon />
                                    </ZoomButton>
                                </div>
                            </div>
                            {sectionLength > 1 && <div style={{ 
                                display: 'flex',
                                justifyContent: 'space-around',
                                margin: '20px 0',
                            }}>
                                {(new Array(sectionLength).fill(0)).map((option, optionIndex) => 
                                    <OptionBubble 
                                        key={optionIndex}
                                        selected={segmentRouteState && optionIndex === segmentRouteState[sectionIndex]} 
                                        onClick={() => {
                                            dispatch(updateSegmentRouteState({sectionIndex, optionIndex}));
                                            dispatch(updateHighlightFeatureIDs({
                                                highlightFeatureIDs: undefined,
                                            }))
                                            dispatch(updatePath(undefined));
                                        }}
                                    >
                                </OptionBubble>)}
                            </div>}
                            {!isOptionDataEmpty && <div style={{display: 'flex', width: '100%', textAlign: 'center', color: '#FFFFFF', margin: '20px 0'}}>
                                <div style={{width: '25%', borderRight: '1px solid rgba(255,255,255,0.2)'}}>
                                    <div>Distance</div>
                                    <div style={{fontSize: '14px', color: "#aaa"}}>{distance}</div>
                                </div>
                                <div style={{width: '25%', borderRight: '1px solid rgba(255,255,255,0.2)'}}>
                                    <div>Top Speed</div>
                                    <div style={{fontSize: '14px', color: "#aaa"}}>{topSpeed}</div>
                                </div>
                                <div style={{width: '25%', borderRight: '1px solid rgba(255,255,255,0.2)'}}>
                                    <div>Avg Speed</div>
                                    <div style={{fontSize: '14px', color: "#aaa"}}>{avgSpeed}</div>
                                </div>
                                <div style={{width: '25%'}}>
                                    <div>Time</div>
                                    <div style={{fontSize: '14px', color: "#aaa"}}>{travelTime}</div>
                                </div>
                            </div>}
                            <div style={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '14px',
                                lineHeight: '23px',
                                letterSpacing: '0.1px',
                                color: '#B7B7B7',
                            }}>
                                {feature.segmentData[sectionIndex][segmentRouteState[sectionIndex]]?.description}
                            </div>
                        </div>}
                    )}
                </OptionsContainer>
                break;
            case TabKey.Paths:
                const pathIDs = pathData?.ids?.split(',')
                const stationIDs = pathIDs?.filter((s: string) => !s.includes('_L'))
                // Multiple by constant to convert from KM to MI
                const distanceMI = kmToMi(pathData?.distanceKM)
                // Divide by avg Hyperloop speed to get travel time
                const calcTravelTime = distanceMI / 386
                const planeTravelTime = (distanceMI / 350) + 1
                const carTravelTime = distanceMI / 52.5
                const trainTravelTime = distanceMI / 45

                if (menuData[feature.companyID]?.techType === 'Loop') {
                    return <PathsContainer>
                        <motion.div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '140px', color: 'grey', padding: '0 10px', textAlign: 'center'}}>
                            Trips aren't currently available for Loop routes.
                        </motion.div>
                    </PathsContainer>
                }

                return <PathsContainer>
                    <div style={{width: '100%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', borderBottom: '1px solid #454545', borderLeft: '1px solid #454545', borderRight: '1px solid #454545', borderRadius: '0 0 16px 16px', paddingBottom: '10px'}}>
                        <div style={{width: '100%', display: 'flex'}}> 
                            <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <div style={{fontSize: '14px', fontFamily: 'Poppins', color: loopStyles.colors.tertiary}}>Departure</div>
                                <StationSelect value={departureStationID || ""} onChange={(ev: any) => { setDepartureStationID(ev.target.value) }}>
                                    <option style={{color: 'black'}} value="" selected disabled>Departure Station</option>
                                    {selectedChildFeatureIDs
                                        ?.filter((id: string) => id !== arrivalStationID && feature.stationNames[id])
                                        .map((id: string) =>  <option key={id} style={{color: 'black'}} value={id}>{feature.stationNames[id]}</option>)
                                    }
                                </StationSelect>
                            </div>
                            <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <div style={{fontSize: '14px', fontFamily: 'Poppins', color: loopStyles.colors.tertiary}}>Arrival</div>
                                <StationSelect value={arrivalStationID || ""} onChange={(ev: any) => { setArrivalStationID(ev.target.value) }}>
                                    <option style={{color: 'black'}} value="" selected disabled>Arrival Station</option>
                                    {selectedChildFeatureIDs
                                        ?.filter((id: string) => id !== departureStationID && feature.stationNames[id])
                                        .map((id: string) =>  <option key={id} style={{color: 'black'}} value={id}>{feature.stationNames[id]}</option>)
                                    }
                                </StationSelect>
                            </div>
                        </div>
                        <AnimatePresence exitBeforeEnter>
                            {(!isLoadingPath && departureStationID && arrivalStationID) && <motion.div 
                                key={departureStationID+arrivalStationID}
                                initial={{ opacity: 0, height: 0 }}
                                exit={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: 'auto' }}
                                transition={{ duration: 0.3 }}
                                style={{ padding: '0 20px', overflow: 'hidden' }}
                            >
                                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '8px'}}>
                                    <div style={{ color: '#B5B5BE', fontFamily: 'Poppins', opacity: 0.6 }}>Distance</div>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <div style={{ color: '#fff', fontFamily: 'Poppins', fontWeight: 700, fontSize: '24px' }}>{Math.round(distanceMI)} <span style={{ fontFamily: 'Roboto', color: '#92929D', fontSize: '14px' }}>miles</span></div>
                                        <div style={{ color: '#92929D', fontSize: '10px', margin: '0 8px' }}>or</div>
                                        <div style={{ color: '#fff', fontFamily: 'Poppins', fontWeight: 700, fontSize: '24px' }}>{Math.round(pathData?.distanceKM)} <span style={{ fontFamily: 'Roboto', color: '#92929D', fontSize: '14px' }}>km</span></div>
                                    </div>
                                </div>
                                <div style={{display: 'flex', width: '100%', userSelect: 'none'}}>
                                    <div style={{flex: '4 0 0 '}}>
                                        <div style={{paddingBottom: '4px'}}>
                                            <div style={{fontFamily: 'Poppins', fontSize: '12px', fontWeight: 600, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}><AllInclusiveIcon style={{height: '12px', width: 'auto', marginRight: '4px'}}/>Hyperloop</div>
                                                <div>{stringifyHours(calcTravelTime)}</div>
                                            </div>
                                            <div style={{marginTop: '4px', height: '8px', width: '100%', backgroundColor: '#4C4C4D', borderRadius: '8px', overflow: 'hidden'}}>
                                                <SpeedBar animate={{ width: `${(calcTravelTime/trainTravelTime)*100}%` }} />
                                            </div>
                                        </div>
                                        {distanceMI >= 50 && <div style={{paddingBottom: '4px'}}>
                                            <div style={{fontFamily: 'Poppins', fontSize: '12px', fontWeight: 600, display: 'flex', justifyContent: 'space-between', alignItems: 'center', opacity: 0.5}}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}><AirplanemodeActiveIcon style={{height: '12px', width: 'auto', marginRight: '4px'}}/>Airplane</div>
                                                <div>{stringifyHours(planeTravelTime)}</div>
                                            </div>
                                            <div style={{marginTop: '4px', height: '8px', width: '100%', backgroundColor: '#4C4C4D', borderRadius: '8px', overflow: 'hidden'}}>
                                                <motion.div animate={{ width: `${(planeTravelTime/trainTravelTime)*100}%` }} style={{ height: '100%', borderRadius: '8px', backgroundColor: '#ACB2B2'}} />
                                            </div>
                                        </div>}
                                        <div style={{paddingBottom: '4px'}}>
                                            <div style={{fontFamily: 'Poppins', fontSize: '12px', fontWeight: 600, display: 'flex', justifyContent: 'space-between', alignItems: 'center', opacity: 0.5}}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}><DirectionsCarIcon style={{height: '12px', width: 'auto', marginRight: '4px'}}/>Car</div>
                                                <div>{stringifyHours(carTravelTime)}</div>
                                            </div>
                                            <div style={{marginTop: '4px', height: '8px', width: '100%', backgroundColor: '#4C4C4D', borderRadius: '8px', overflow: 'hidden'}}>
                                                <motion.div animate={{ width: `${(carTravelTime/trainTravelTime)*100}%` }} style={{ height: '100%', borderRadius: '8px', backgroundColor: '#ACB2B2'}} />
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{fontFamily: 'Poppins', fontSize: '12px', fontWeight: 600, display: 'flex', justifyContent: 'space-between', alignItems: 'center', opacity: 0.5}}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}><TrainIcon style={{height: '12px', width: 'auto', marginRight: '4px'}}/>Train</div>
                                                <div>{stringifyHours(trainTravelTime)}</div>
                                            </div>
                                            <div style={{marginTop: '4px', height: '8px', width: '100%', backgroundColor: '#4C4C4D', borderRadius: '8px', overflow: 'hidden'}}>
                                                <motion.div animate={{ width: `100%` }} style={{ height: '100%', borderRadius: '8px', backgroundColor: '#ACB2B2'}} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>}
                        </AnimatePresence>
                    </div>
                    <PathList>
                        {/* <AnimatePresence exitBeforeEnter> */}
                            {isLoadingPath && <motion.div 
                                style={{maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', maxHeight: '100%'}}
                                initial={{opacity: 0}}
                                exit={{opacity: 0}}
                                animate={{opacity: 1}}
                                key='loading'
                            >
                                <CircularProgress style={{color: loopStyles.colors.tertiary}}/>
                            </motion.div>}
                            {!isLoadingPath && ((departureStationID && arrivalStationID) ? <motion.div 
                                style={{maxWidth: '100%', textWrap: 'wrap', wordWrap: 'break-word', padding: '10px 20px', height: 'min-content', maxHeight: '100%'}}
                                initial={{opacity: 0}}
                                exit={{opacity: 0}}
                                animate={{opacity: 1}}
                                key='content'
                            >
                                {!!pathData?.ids ? <div>
                                    {stationIDs?.map((featureID: string, index: number) => {
                                        // console.log(featureID, index)
                                        // console.log(pathIDs)
                                        // console.log(pathIDs.indexOf(featureID)+1, pathIDs.indexOf(stationIDs[index+1]) - 1)
                                        // console.log(pathIDs.slice(pathIDs.indexOf(featureID)+1, pathIDs.indexOf(stationIDs[index+1])))
                                        const travelDistance = pathIDs.slice(pathIDs.indexOf(featureID)+1, pathIDs.indexOf(stationIDs[index+1])).reduce((acc: number, pid: string) => acc + feature.distances[pid], 0)
                                        const stationDistMi = kmToMi(travelDistance)
                                        const stationTravelTime = stationDistMi / 386 // 386 mph avg speed for Hyperloop

                                        return <div key={featureID} style={{fontFamily: 'Roboto'}}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{ backgroundColor: (loopStyles as any)[feature?.featureID.split('_')[0]]?.colors.primary, borderRadius: '50%', width: '12px', height: '12px', margin: '16px 0' }}/>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <div style={{margin: '0 4px 0 14px', color: loopStyles.colors.secondary, fontFamily: 'Roboto', fontWeight: 600}}>{feature.stationNames[featureID]}</div>
                                                    {index > 0 && index < stationIDs.length - 1 && <div style={{margin: '0 2px', color: loopStyles.colors.secondary, fontSize: '14px', opacity: 0.5, fontFamily: 'Roboto', fontWeight: 600}}>(no stops)</div>}
                                                </div>
                                            </div>
                                            {index !== stationIDs.length - 1 && <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{minWidth: '2px', height: '40px', margin: '0 5px', backgroundColor: 'rgba(255,255,255,0.5)'}} />
                                                <div style={{marginLeft: '16px', fontSize: '14px', letterSpacing: '1px', fontFamily: 'Poppins', color: '#B5B5BE', fontWeight: 600}}>
                                                    {stringifyHours(stationTravelTime)}
                                                </div>
                                            </div>}
                                        </div>
                                    }) }
                                </div> : <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'grey', height: '140px'}}>Info for this trip is currently unavailable.</div>}
                            </motion.div> : <motion.div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '140px', color: 'grey', padding: '0 10px', textAlign: 'center'}}>
                                Select a departure/arrival station to view trip info.
                            </motion.div>)}
                        {/* </AnimatePresence> */}
                    </PathList>
                </PathsContainer>
            case TabKey.Study:
                content = <StudyContainer>
                    <div>
                        <StudyHeader>Total Infrastructure Cost</StudyHeader>
                        <div style={{color: loopStyles.colors.tertiary, fontSize: '32px'}}>$46.61B</div>
                    </div>
                    <div style={{display: 'flex', marginTop: '10px', justifyContent: 'space-around'}}>
                        <div style={{flex: 1}}>
                            <StudyHeader style={{fontSize: '14px'}}>Investment (per Mile)</StudyHeader>
                            <div style={{color: loopStyles.colors.tertiary}}>$97.2M</div>
                        </div>
                        <div style={{flex: 1}}>
                            <StudyHeader style={{fontSize: '14px'}}>Return (per Mile)</StudyHeader>
                            <div style={{color: loopStyles.colors.tertiary}}>$629.8M</div>
                        </div>
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <StudyHeader>Net Metrics</StudyHeader>
                        <div style={{display: 'grid', gridTemplateColumns: '3fr 4fr', gridTemplateRows: '1fr 1fr 1fr', gap: '10px', fontSize: '14px', color: '#B5B5BE', fontWeight: 600, marginTop: '10px'}}>
                            <div >Total Investment Required</div>
                            <div style={{textAlign: 'right'}}>
                                <GridValue style={{fontSize: '18px'}}>$46.61B</GridValue>
                            </div>

                            <div >Total Economic Return</div>
                            <div style={{textAlign: 'right'}}>
                                <GridValue>$302.03B</GridValue>
                                <div style={{fontSize: '16px'}}><span style={{color: loopStyles.colors.tertiary}}>6.48x</span> on investment</div>
                            </div>

                            <div >Net Economic Benefit</div>
                            <div style={{textAlign: 'right'}}>
                                <GridValue>$255.42B</GridValue>
                                <div style={{fontSize: '16px'}}><span style={{color: loopStyles.colors.tertiary}}>5.48x</span> on investment</div>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <StudyHeader>Station Growth 2025-2055</StudyHeader>
                        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gridTemplateRows: '1fr 1fr 1fr 1fr 1fr', gap: '10px', fontSize: '14px', color: '#B5B5BE', fontWeight: 600, marginTop: '10px'}}>
                            <div></div>
                            <div style={{textAlign: 'right'}}>2035</div>
                            <div style={{textAlign: 'right'}}>2045</div>
                            <div style={{textAlign: 'right'}}>2055</div>

                            <div>Chicago</div>
                            <GridValue style={{color: "#fff"}}>5.53M</GridValue>
                            <GridValue style={{color: "#fff"}}>7.15M</GridValue>
                            <GridValue style={{color: "#fff"}}>9.26M</GridValue>

                            <div>Cleveland</div>
                            <GridValue style={{color: "#fff"}}>6.58M</GridValue>
                            <GridValue style={{color: "#fff"}}>8.52M</GridValue>
                            <GridValue style={{color: "#fff"}}>10.69M</GridValue>

                            <div>Pittsburgh</div>
                            <GridValue style={{color: "#fff"}}>4.67M</GridValue>
                            <GridValue style={{color: "#fff"}}>6.01M</GridValue>
                            <GridValue style={{color: "#fff"}}>7.60M</GridValue>

                            <div>Total</div>
                            <GridValue>16.77M</GridValue>
                            <GridValue>21.68M</GridValue>
                            <GridValue>27.55M</GridValue>
                        </div>
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <StudyHeader>Property Value Impact Summary (2055)</StudyHeader>
                        <div style={{display: 'grid', gridTemplateColumns: '1fr 4fr 4fr 4fr', gridTemplateRows: '1fr 1fr 1fr 1fr 1fr', gap: '10px', fontSize: '14px', color: '#B5B5BE', fontWeight: 600, marginTop: '10px'}}>
                            <div></div>
                            <div style={{fontSize: '10px', textAlign: 'right'}}>Development Value</div>
                            <div style={{fontSize: '10px', textAlign: 'right'}}>Property Value Premium</div>
                            <div style={{fontSize: '10px', textAlign: 'right'}}>Combined Impact</div>

                            <div>Chicago</div>
                            <GridValue style={{color: "#fff"}}>$33.65B</GridValue>
                            <GridValue style={{color: "#fff"}}>$4.82B</GridValue>
                            <GridValue style={{color: "#fff"}}>$38.47B</GridValue>

                            <div>Cleveland</div>
                            <GridValue style={{color: "#fff"}}>$15.71B</GridValue>
                            <GridValue style={{color: "#fff"}}>$2.34B</GridValue>
                            <GridValue style={{color: "#fff"}}>$18.05B</GridValue>

                            <div>Pittsburgh</div>
                            <GridValue style={{color: "#fff"}}>$14.72B</GridValue>
                            <GridValue style={{color: "#fff"}}>$2.68B</GridValue>
                            <GridValue style={{color: "#fff"}}>$17.40B</GridValue>

                            <div>Total</div>
                            <GridValue>$64.08B</GridValue>
                            <GridValue>$9.84B</GridValue>
                            <GridValue>$73.92B</GridValue>
                        </div>
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <StudyHeader>Economic Returns (2055)</StudyHeader>
                        <div style={{display: 'grid', gridTemplateColumns: '3fr 4fr', gridTemplateRows: '1fr 1fr 1fr 1fr', gap: '10px', fontSize: '14px', color: '#B5B5BE', fontWeight: 600, marginTop: '10px'}}>
                            <div style={{fontSize: '12px'}}>Direct Transporation Benefits</div>
                            <div style={{textAlign: 'right'}}>
                                <GridValue>$66.65B</GridValue>
                                <div style={{fontSize: '10px'}}>Time savings & operational benefits</div>
                            </div>

                            <div style={{fontSize: '12px'}}>Growth & Development Effects</div>
                            <div style={{textAlign: 'right'}}>
                                <GridValue>$128.18B</GridValue>
                                <div style={{fontSize: '10px'}}>Network and economic growth</div>
                            </div>

                            <div style={{fontSize: '12px'}}>Tax Benefits</div>
                            <div style={{textAlign: 'right'}}>
                                <GridValue>$107.2B</GridValue>
                                <div style={{fontSize: '10px'}}>Fiscal Impact</div>
                            </div>

                            <div style={{fontSize: '12px'}}>Total Economic Impact</div>
                            <div style={{textAlign: 'right'}}>
                                <GridValue>$302.03B</GridValue>
                                <div style={{fontSize: '10px'}}><span style={{color: loopStyles.colors.tertiary}}>6.48x</span> on investment</div>
                            </div>
                        </div>
                    </div>
                </StudyContainer>
                break;
        }

        return content
    }

    // 85 for top padding, 15 for bottom
    return <Container>
        <InvisibleScroll>
            <Tabs
                value={tabIndex}
                onChange={handleChange}
                className={classes.tabs}
                TabIndicatorProps={{ style: { backgroundColor: loopStyles.colors.tertiary } }}
            >
                {!isPathsEmpty && <Tab 
                    onClick={() => { setTab(TabKey.Paths) }} 
                    selected={tab === TabKey.Paths}
                    className={classes.tab} 
                    label="Trips" 
                />}
                {!isDetailsEmpty && <Tab 
                    onClick={() => { setTab(TabKey.Info) }} 
                    selected={tab === TabKey.Info}
                    className={classes.tab} 
                    label="Info" 
                />}
                {!isOptionsEmpty && <Tab 
                    onClick={() => { setTab(TabKey.Options) }} 
                    selected={tab === TabKey.Options}
                    className={classes.tab} 
                    label="Forms" 
                />}
                {isGreatLakes && <Tab 
                    onClick={() => { setTab(TabKey.Study) }} 
                    selected={tab === TabKey.Study}
                    className={classes.tab} 
                    label="Study" 
                />}
            </Tabs>
        </InvisibleScroll>
        {getTabContent(tabIndex)}
    </Container>
}

const StudyContainer = styled.div`
    font-family: 'Poppins';
    font-size: 24px;
    color: #B5B5BE;
    font-weight: 600;
    padding: 10px 20px;
    overflow-y: auto;
    border-top: 1px solid #454545;
    ${loopStyles.scrollbar.default}
`

const GridValue = styled.div`
    color: ${loopStyles.colors.tertiary};
    text-align: right;
`

const StudyHeader = styled.div`
    font-family: 'Poppins';
    font-size: 18px;
    color: #fff;
    font-weight: 600;
`

const moveGradient = keyframes`
 0% {
    background-position: -100% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
`

const SpeedBar = styled(motion.div)`
    height: 100%;
    border-radius: 8px;
    background-size: 200%;
    background: linear-gradient(90.17deg, #00FDFD 49.85%, #0094FF 81.19%);
    -webkit-animation: ${moveGradient} 3s linear infinite;
    animation: ${moveGradient} 3s linear infinite;
`

const PathList = styled.div`
    max-height: 100%;
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 0.4em;
    };
    &::-webkit-scrollbar-thumb {
        background-color: ${loopStyles.colors.tertiary};
        border: 4px solid transparent;
        border-radius: 16px;
    }
`

const StationSelect = styled.select`
    margin: 10px 0;
    color: #fff;
    background-color: #333231;
    border: 0;
    border: 1px solid transparent;
    outline: 0;
    width: 160px;
    font-family: Roboto;
    padding: 10px 16px;
    height: 38px;
    border-radius: 2px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    ${loopStyles.mediaQueries.desktop} {
        &:hover {
            background-color: #444342;
            border: 1px solid ${loopStyles.colors.tertiary};
        }
    }
    ${loopStyles.transition.animationStyles}
`

const Container = styled.div`
    height: min-content;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    ${loopStyles.mediaQueries.desktop} {
        max-height: calc(100vh - 178px);
    }
    ${loopStyles.mediaQueries.mobile} {
        height: 100%;
        // max-height: 100%;
        max-height: calc(100vh - 188px);
    }
`

const EmptyDetailsLabel = styled.div`
    width: 100%;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.1px;
    margin-bottom: 10px;
    color: #B7B7B7;
`

const InvisibleScroll = styled.div`
    width: 100%;
    overflow-x: auto;
    height: min-content;
    min-height: 48px;
    margin: 0;
    ${loopStyles.scrollbar.hidden}
`

const DetailsContainer = styled.div`
    width: 100%;
    padding: 10px 0;
    height: min-content;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 0.4em;
    };
    &::-webkit-scrollbar-thumb {
        background-color: ${loopStyles.colors.tertiary};
        border: 4px solid transparent;
        border-radius: 16px;
    }
`

const PathsContainer = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    padding: 10px 0 0;
    color: white;
    &::-webkit-scrollbar {
        width: 0.4em;
    };
    &::-webkit-scrollbar-thumb {
        background-color: ${loopStyles.colors.tertiary};
        border: 4px solid transparent;
        border-radius: 16px;
    }
`

const OptionsContainer = styled.div`
    width: 100%;
    // min-height: 240px;
    flex: 1;
    // height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 0;
    color: white;
    &::-webkit-scrollbar {
        width: 0.4em;
    };
    &::-webkit-scrollbar-thumb {
        background-color: ${loopStyles.colors.tertiary};
        border: 4px solid transparent;
        border-radius: 16px;
    }
`

type OptionBubbleProps = {
    selected: boolean
}

const OptionBubble = styled.div<OptionBubbleProps>`
    width: 20px;
    height: 20px;
    border: 1px solid ${loopStyles.colors.tertiary};
    border-radius: 50%;
    background-color: ${props => props.selected ? loopStyles.colors.tertiary : loopStyles.colors.primary};
    color: ${props => props.selected ? loopStyles.colors.primary : loopStyles.colors.secondary};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    ${loopStyles.transition.animationStyles}
    &:hover {
       color: ${loopStyles.colors.primary}; 
       background-color: ${loopStyles.colors.tertiary};
    }
`

const ZoomButton = styled.div`
    display: flex;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    align-items: center;
    padding: 6px 8px;
    cursor: pointer;
    background-color: rgba(0,0,0,0);
    user-select: none;
    white-space: nowrap;
    &:hover {
        background-color: rgba(0,0,0,0.3);
    }
    ${loopStyles.transition.animationStyles}
`

const styles = {
    tab: {
        width: '100px',
        minWidth: '100px',
        borderRadius: '10px 10px 0 0',
        borderLeft: `1px solid #434343`,
        borderRight: `1px solid #434343`,
        borderTop: `1px solid #434343`,
        color: loopStyles.colors.secondary,
        margin: '0 2px',
        transition: 'all 0.2s ease-in',
        '&.Mui-selected, &:hover': {
            borderLeft: `1px solid ${loopStyles.colors.secondary}`,
            borderRight: `1px solid ${loopStyles.colors.secondary}`,
            borderTop: `1px solid ${loopStyles.colors.secondary}`,
        },
    },
    tabs: {
        // marginTop: '8px',
        borderBottom: '1px solid #434343',
        color: 'white',
        minWidth: '100%',
        width: 'min-content',
        height: 'fit-content',
        '&:selected': {
            color: '#1260cc',
        },
    },
    indicator: {
      backgroundColor: 'none',  
      color: '#1890ff',
    },
}

export default withStyles(styles)(RouteLayout);